import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    class1955: Core.ImageAttributes;
    inauguration: Core.ImageAttributes;
    campus: Core.ImageAttributes;
    rooftop: Core.ImageAttributes;
    entrance: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { class1955, inauguration, campus, rooftop, entrance } = props.data;

  return (
    <Layout title="65 Years of SAIS in Europe">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              <Core.LeadIn>by Susannah Tillson</Core.LeadIn>
            </Core.Caption>
          }
        >
          <Core.LargeText fontStyle="sans">
            More than six decades ago, an experiment in international education
            was born in Bologna, Italy. Today, the center that started as a
            place to foster postwar transatlantic dialogue continues to bring a
            valuable European perspective to complex global issues.
          </Core.LargeText>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              Above: The Bologna Center was inaugurated in 1961 with an
              award-winning architectural design by Enzo Zacchiroli (Best
              Building from Italy’s National Institute of Architecture).
            </Core.Caption>
          }
        >
          <p>
            On Feb. 22, 1955, with four professors, 10 students, and borrowed
            classrooms, Professor C. Grove Haines launched an experiment in
            international education. Haines, who had been the first full-time
            professor at SAIS, established 12 years earlier in 1943, wanted to
            provide advanced training to a select group of SAIS students
            representing various nationalities who were preparing for careers in
            international affairs.
          </p>
          <p>
            The vision was to offer these students the experience of living and
            studying together under a multinational faculty in an international
            environment, thereby encouraging cross-cultural communication and
            fostering collaboration among world leaders. Most students at the
            SAIS-affiliated center would work toward a Master of Arts in
            international relations, which required one year of study in Bologna
            and then the second year at SAIS in Washington, D.C.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage image={class1955} alt="Class with 1955" />
              <Core.Caption contentSectionPlacement="body">
                SAIS Europe began its first semester in 1955 with 10 students:
                two Austrians, two French, two Italians, and four Americans.
              </Core.Caption>
            </>
          }
        >
          <Core.BigQuote color="beige">
            The Bologna Center was renamed SAIS Europe in 2013, to emphasize the
            campus’ standing as an integral part of Johns Hopkins SAIS based in
            Europe.
          </Core.BigQuote>
          <p>
            In 1952, the rector of the University of Bologna, the oldest
            university in Europe, enthusiastically supported Haines’ proposal to
            establish the school in Bologna, offering classroom and office space
            as well as access to the university’s vast library collection. The
            first class in 1955 included two French, two Italian, and four
            American students. Two American professors (including Haines) and
            two scholars from France and Italy taught courses on the economics
            and politics of France, Germany, Austria, and Italy during that
            first spring semester. By the autumn of 1955, Haines had recruited
            seven additional lecturers who commuted to Bologna from all over
            Europe to teach 18 American and 22 European students. The center
            quickly outgrew its modest quarters, and Haines sought funding to
            build a larger, permanent structure. He succeeded in securing
            assistance from the U.S. government and selected a site on via
            Belmeloro, in the heart of the city’s university quarter. The
            Bologna Center was inaugurated on April 27, 1961, by then-Johns
            Hopkins University President Milton S. Eisenhower. The U.S.
            Information Service donated a collection of 10,000 books, with the
            understanding that the center’s library be open to the public.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage image={campus} alt="Students on campus" />
              <Core.FullWidthImage
                image={rooftop}
                alt="Students on the rooftop"
              />
              <Core.Caption contentSectionPlacement="body">
                Students and faculty members on campus in Bologna and enjoying
                the rooftop terrace.
              </Core.Caption>
            </>
          }
        >
          <Core.FullWidthImage image={inauguration} alt="Inauguration" />
          <Core.Caption contentSectionPlacement="body">
            The Bologna Center was inaugurated on April 27, 1961, with
            then-Johns Hopkins University President Milton S. Eisenhower and
            Professor C. Grove Haines.
          </Core.Caption>
          <p>
            Haines’ vision for a postwar transatlantic dialogue to support the
            creation of a peaceful and prosperous postwar order continues to
            this day. The Bologna Center was renamed SAIS Europe in 2013, to
            emphasize the campus’ standing as an integral part of Johns Hopkins
            SAIS based in Europe.
          </p>
          <p>
            “Our Bologna campus brings to SAIS a diverse, European perspective
            to complex global issues,” notes SAIS Europe Director Mike Plummer.
            “The dedication of its faculty and administrators remains
            exceptional; the achievements of our students and the loyalty of its
            alumni are also clear indicators of SAIS Europe’s enduring impact.”
          </p>
          <p>
            Today, SAIS Europe students hail from 40 countries, training under a
            multinational, multidisciplinary faculty. They continue to form
            lifelong friendships and professional connections that sustain them
            through careers as leaders in all sectors of international affairs
            worldwide. The 65th anniversary celebrates Haines’ vision and the
            center’s 8,000+ alumni from 115 nations — ambassadors of Bologna and
            of SAIS Europe in the world.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.Blockquote color="beige" noQuotes>
                THE EUROPEAN COMMUNITY AND THE UNITED STATES: MAY 1965
              </Core.Blockquote>
              <Core.Caption>
                Earlier this year, we discovered some footage from the school in
                its early years — a clip from a conference held at the SAIS
                Europe Bologna Center on May 20, 1965. Chaired by Professor
                Wilson Schmidt, the three-day conference was titled “The
                European Community and the United States.”
              </Core.Caption>
              <br />
              <Core.Caption>
                With video restoration help from RAI, the Italian national
                television network, SAIS Europe leaders have been able to share
                a portion of the video. In it, Heinrik N. Boon, who was then the
                Netherlands representative at the Organisation for Economic
                Co-operation and Development and NATO, secretary-general of the
                Netherlands’ Ministry of Foreign Affairs, and ambassador to
                Italy and Venezuela, talks about how important it is to have
                open policy discussions between young students from different
                international backgrounds.
              </Core.Caption>
              <br />
              <Core.Caption>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://europe.jhu.edu/saiseurope65/history.cfm"
                >
                  Watch the Video
                </a>
              </Core.Caption>
            </>
          }
        >
          <Core.FullWidthImage image={entrance} alt="SAIS Europe Entrance" />
          <Core.TertiaryHeading>
            Celebrating 65 Years of ‘Bolognesi’
          </Core.TertiaryHeading>

          <p>
            As the world responded to the global pandemic, SAIS Europe’s planned
            series of worldwide anniversary events had to be modified. Leaders
            at SAIS and the Johns Hopkins University made the difficult but
            necessary decision to transform many of the 65th anniversary events
            into virtual events, including SAIS Europe’s largest annual
            gathering, Alumni Weekend. Hence, for its customary two days in May,
            SAIS Europe virtually welcomed close to 600 alumni, traditionally
            referred to as “Bolognesi,” to a 100% virtual event.
          </p>
          <p>
            Alumni, faculty, staff members, and guests from all continents and
            53 countries attended seminars and panels online with SAIS
            professors; speeches by SAIS Director Michael Plummer, Johns Hopkins
            University President Ronald Daniels, and SAIS Dean Eliot Cohen;
            career panels between groups of alumni and current students; and
            special separate sessions for class reunions. The event also
            included less formal virtual “spritz receptions” for alumni, which
            were emotional sessions, particularly for those Bolognesi with
            special anniversaries to celebrate.
          </p>
          <p>
            Despite not being able to connect in person, cohorts still reunited
            to share nostalgia from their year in Bologna, and all vowed over
            Campari spritz toasts to see each other in person at Alumni Weekend
            2021. Plans are underway for other signature virtual alumni events
            this fall, including the Amici di Bologna event organized out of New
            York City, a Berlin alumni event in October, and a Bolognesi a
            Londra event organized from London for November. Alumni can find out
            more details and can register for the virtual events on the SAIS
            Europe 65th anniversary{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://europe.jhu.edu/saiseurope65/"
            >
              website
            </a>
            .
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    class1955: file(
      relativePath: { regex: $directory }
      name: { eq: "class1955" }
    ) {
      ...MarginaliaImage
    }
    inauguration: file(
      relativePath: { regex: $directory }
      name: { eq: "inauguration" }
    ) {
      ...FullWidthImage
    }
    campus: file(
      relativePath: { regex: $directory }
      name: { eq: "students-campus" }
    ) {
      ...MarginaliaImage
    }
    rooftop: file(
      relativePath: { regex: $directory }
      name: { eq: "students-rooftop" }
    ) {
      ...MarginaliaImage
    }
    entrance: file(
      relativePath: { regex: $directory }
      name: { eq: "sais-europe-entrance" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default FeatureContent;
