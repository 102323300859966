import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

const Heading: React.FC = () => {
  const { mastheadImage } = useStaticQuery<{
    mastheadImage: Core.ImageAttributes;
  }>(graphql`
    {
      mastheadImage: file(
        relativePath: {
          regex: "/issues/fall-2020/features/65-years-of-sais-in-europe/images/"
        }
        name: { eq: "masthead" }
      ) {
        ...FullWidthImage
      }
    }
  `);

  return (
    <Core.Masthead color="light" className="SixtyFiveYearsHeading">
      <div className="SixtyFiveYearsHeading-image">
        <Core.FullWidthImage
          image={mastheadImage}
          alt="65 Years of Sais in Europe"
        />
      </div>
      <Core.ContentWrapper className="SixtyFiveYearsHeading-wrapper">
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <h1 className="SixtyFiveYearsHeading-title">
          65 Years of SAIS in Europe
        </h1>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default Heading;
